import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import eheImage from "../../images/deeper-dive-ehe-eta.png";

const RelatedContent = (props) => {
  return (
    <Container className="related-content section mt-2 pt-1 mb-4">
      {props.page === "blind-angels" && (
        <Row>
          <Col xs={12} className="m-auto ">
            <h2 className="title">RELATED CONTENT</h2>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} className="m-auto">
          <Container>
            <Row>
            <h2 className='title w-100'>TESTING AND PREVENTION</h2>
            <Row className="mb-standard">
            
              <Col xs={12} lg={4} className='mb-3 mb-lg-4 card-callout'>
                <a className='d-flex flex-column justify-content-between' href='../../deeper-dive/callen-lorde'>
                    <div>
                      <h3>Gilead Sponsors Callen-Lorde's National HIV Testing Day Event</h3>
                      <p className='title-one-line'>
                      Check out our conversation with Aruna Krishnakumar, Managing
                    Director Adolescent & Mobile Health at Callen-Lorde, who
                    discusses how Callen-Lorde will help bring HIV testing to the
                    community in a live event in New York City.
                      </p>
                    </div>
                    
                    <span>
                      <span>Read more</span>
                      &nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </span>
                </a>
              </Col>
              <Col xs={12} lg={4} className='mb-3 mb-lg-4 card-callout'>
                <a className='d-flex flex-column justify-content-between' href='../../deeper-dive/zakia'>
                    <div>
                      <h3>Mobile Health Units Bring HIV Testing to Underserved Communities in the American&nbsp;South</h3>
                      <p className='title-one-line'>
                      Check out our conversation with Nationz Foundation Executive Director Zakia McKensey.
                      </p>
                    </div>
                    
                    <span>
                      <span>Read more</span>
                      &nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </span>
                </a>
              </Col>
              <Col xs={12} lg={4} className='mb-3 mb-lg-4 card-callout'>
                <a className='d-flex flex-column justify-content-between' href='../../deeper-dive/jose-joaquin'>
                    <div>
                      <h3>Overcoming Barriers to PrEP Medicine Use in Hispanic/Latino Communities in the American South</h3>
                      <p className='title-one-line'>
                      Discover how HIV advocates José Romero and Joaquín Carcaño approach the topic of HIV prevention in the South.
                      </p>
                      <p className="footnote">
                        PrEP=pre-exposure prophylaxis.
                      </p>
                    </div>
                    
                    <span>
                      <span>Read more</span>
                      &nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </span>
                </a>
              </Col>
              <Col xs={12} lg={4} className='mb-3 mb-lg-4 card-callout'>
                <a className='d-flex flex-column justify-content-between' href='../../deeper-dive/dazon'>
                    <div>
                      <h3>Empowering Black Women in the American South to Protect Their Sexual Health</h3>
                      <p className='title-one-line'>
                      Learn about the work Dázon Dixon Diallo is doing to improve access to care for women who live with and are at risk for HIV.
                      </p>
                    </div>
                    
                    <div className="readMoreButton">
                      <span><span>Read more</span></span>
                      &nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </div>
                </a>
              </Col>
              <Col xs={12} lg={4} className='mb-3 mb-lg-4 card-callout'>
                <a className='d-flex flex-column justify-content-between' href='../../deeper-dive/marlene-mcneese'>
                    <div>
                      <h3>Recovering From the Impact of the COVID-19 Pandemic on HIV
                      Testing</h3>
                      <p className='title-one-line'>
                      Read our interview with Marlene McNeese, co-chair of the
                    Presidential Advisory Council on HIV/AIDS (PACHA), who
                    discusses the impact of the pandemic on HIV testing and
                    innovative strategies to reinvigorate testing urgency.
                      </p>
                    </div>
                    
                    <div className="readMoreButton">
                      <span><span>Read more</span></span>
                      &nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </div>
                </a>
              </Col>

            </Row>
            <h2 className='title w-100'>BARRIERS TO CARE</h2>
            <Row className="mb-standard">
              <Col xs={12} lg={4} className='mb-3 mb-lg-4 card-callout'>
                <a className='d-flex flex-column justify-content-between' href='../../deeper-dive/tiffany-west'>
                    <div>
                      <h3>Advancing Health Equity With Historically Black Colleges and
                      Universities</h3>
                      <p className='title-one-line'>
                      Tiffany West, Senior Director of Advancing Health and Black
                  Equity, shares how Gilead is helping advance health equity
                  with the Black community and meeting people where they are.
                      </p>
                    </div>
                    
                    <div className="readMoreButton">
                      <span><span>Learn more</span></span>
                      &nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </div>
                </a>
              </Col>
              
              <Col xs={12} lg={4} className='mb-3 mb-lg-4 card-callout'>
                <a className='d-flex flex-column justify-content-between' href='../../deeper-dive/kayla'>
                    <div>
                      <h3>The Importance of Housing Stability for Transgender Women of
                      Color in HIV Treatment</h3>
                      <p className='title-one-line'>
                      Discover how Kayla Rena Gore's grassroots organization is
                    advocating for transgender women of color and their need for
                    stable housing.
                      </p>
                    </div>
                    
                    <div className="readMoreButton">
                      <span><span>Read more</span></span>
                      &nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </div>
                </a>
              </Col>
              
              <Col xs={12} lg={4} className='mb-3 mb-lg-4 card-callout'>
                <a className='d-flex flex-column justify-content-between' href='../../deeper-dive/kayla-quimbley-video'>
                    <div>
                      <h3>From Poetry to Presidents</h3>
                      <p className='title-one-line'>
                      Kayla Quimbley—the youngest member of the Presidential
                    Advisory Council on HIV/AIDS—is shifting perspectives on what
                    it means to live with HIV. Check out a video of her live
                    performance of her poem, "Thriving with HIV," from Gilead's
                    plenary session at USCHA 2023.
                      </p>
                    </div>
                    
                    <div className="readMoreButton">
                      <span><span>Read more</span></span>
                      &nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </div>
                </a>
              </Col>
              
            </Row>
            
            </Row>

          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default RelatedContent;
